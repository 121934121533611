import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import STATES from "../../../jsonData/state.json";
import CITIES from "../../../jsonData/city.json";

// Register the necessary components
ChartJS.register(
  CategoryScale, // X-axis category scale
  LinearScale, // Y-axis linear scale
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ActivePriceChart = () => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [stateList, setStateList] = useState(
    STATES.filter((elem) => elem.countryId == 102)
  );
  const [cityList, setCityList] = useState(
    STATES.filter((elem) => elem.countryId == 102)
  );
  const [fromDate, setFromDate] = useState(() => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    return lastMonth.toISOString().split("T")[0]; // Default to 1 month ago
  });

  const [toDate, setToDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Default to today
  });

  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const findState = stateList.find((elem) => elem.name === selectedState);
      setCityList(CITIES.filter((elem) => elem.stateId == findState.id));
    } else {
      setCityList([]);
    }
  }, [selectedState]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_AWS_URL}/admin/active/user/graph/data/v1`,
        {
          params: {
            fromDate,
            toDate,
            state: selectedState,
            city: selectedCity,
          },
          headers: { Authorization: localStorage.getItem("token") },
        }
      );
      setGraphData(response.data.data);
    } catch (error) {
      console.error("Error fetching graph data", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  const chartLabels = graphData.map(
    (data) => `${data._id.day}-${data._id.month}-${data._id.year}`
  );
  const chartData = graphData.map((data) => data.count);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Price Collected",
        data: chartData,
        borderColor: "purple",
        borderWidth: 2,
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="p-4">
      <h5 style={{ color: "purple", fontWeight: "bold" }}>Price Collected</h5>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formStartDate">
              <Form.Label>From</Form.Label>
              <Form.Control
                type="date"
                value={fromDate}
                max={toDate} // Prevent selecting future dates for fromDate
                onChange={(e) => setFromDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formEndDate">
              <Form.Label>To</Form.Label>
              <Form.Control
                type="date"
                value={toDate}
                min={fromDate} // Ensure toDate is after fromDate
                max={new Date().toISOString().split("T")[0]} // Restrict future dates
                onChange={(e) => setToDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formEndDate">
              <Form.Label>States</Form.Label>
              <select
                className="form-control"
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  setSelectedCity("");
                }}
              >
                <option value="">All</option>
                {stateList &&
                  stateList.length > 0 &&
                  stateList.map((elem) => (
                    <option key={elem.id} value={elem.name}>
                      {elem.name}
                    </option>
                  ))}
              </select>
            </Form.Group>
          </Col>
          {cityList && cityList.length > 0 && (
            <Col>
              <Form.Group controlId="formEndDate">
                <Form.Label>Cities</Form.Label>
                <select
                  className="form-control"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value="">All</option>
                  {cityList.map((elem) => (
                    <option key={elem.id} value={elem.name}>
                      {elem.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
          )}
        </Row>
        <Button type="submit" variant="primary">
          Filter
        </Button>
      </Form>
      <Line data={data} />
    </div>
  );
};

export default ActivePriceChart;
