import React, { useEffect, useRef, useState } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import * as staticActions from "../../../../redux/staticPage/static.actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { imageBaseUrl } from "../../../../helper";

const UserSupport = () => {
  const [content, setContent] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const editor = useRef(null);
  let editorValue;
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.staticInfo.loading);
  const { userSupport } = useSelector((state) => state.staticInfo);
  const { profile } = useSelector((state) => state.authInfo);

  useEffect(() => {
    dispatch(staticActions.getUserSupportPage());
    uploadImageButton();
  }, []);

  useEffect(() => {
    if (userSupport) {
      setContent(userSupport);
    }
  }, [userSupport]);

  const config = {
    placeholder: "Start Typing......",
    toolbar: true,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    readonly:
      profile?.role === "admin" || profile?.policyAccess?.canCreate
        ? false
        : readOnly,
    uploader: {
      insertImageAsBase64URI: false,
    },
    extraButtons: ["uploadImage"],
    askBeforePasteHTML: false,
    askBeforePasteFromWord: true,
    events: {
      afterOpenPasteDialog: (dialog, msg, title, callback) => {
        dialog.close();
        callback();
      },
    },
  };

  const uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      iconURL:
        "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editorImg) => {
        await imageUpload(editorImg);
      },
    };
  };

  const imageUpload = (editorImg) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }

      const imageInfo = await fileUpload(imageFile);
      const imgUrl = await `${imageBaseUrl}${imageInfo}`;

      insertImage(editorImg, imgUrl);
    }.bind(this);
  };

  const insertImage = (editorImg, url) => {
    const image = editorImg.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editorImg.selection.insertNode(image);
  };

  const fileUpload = async (file) => {
    // upload image on aws S3
    const imgRes = await axios.post(
      `${process.env.REACT_APP_API_AWS_URL}/static/upload/image/v1`,
      {
        sFileName: file.name,
        sContentType: file.type,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const url = imgRes.data.data.sUrl;
    const sImage = imgRes.data.data.sPath;
    await axios.put(url, file, { headers: { "Content-Type": file.type } });

    return sImage;
  };

  const handleBlurEvent = (e) => {
    editorValue = e;
  };

  const handleSave = () => {
    setContent(editorValue);
    dispatch(staticActions.updateStaticPage({ user_support: editorValue }));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col>
              <JoditEditor
                ref={editor}
                value={content}
                onBlur={(newContent) => handleBlurEvent(newContent)}
                config={config}
              />
              {(profile?.role === "admin" ||
                profile?.policyAccess?.canCreate) && (
                <div class="d-grid gap-2 mt-3 ml-2">
                  <button
                    class="btn btn-success"
                    type="button"
                    onClick={(e) => handleSave()}
                  >
                    SAVE
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default UserSupport;
