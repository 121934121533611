import * as settingActions from "./setting.actions";

export const settingFeatureKey = "settingInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  appVersionData: {},
  isStatusChange: false,
  priceCharges: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case settingActions.GET_APP_VERSION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case settingActions.GET_APP_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        appVersionData: payload.data,
      };

    case settingActions.GET_APP_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        appVersionData: {},
      };

    case settingActions.UPDATE_APP_VERSION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case settingActions.UPDATE_APP_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusChange: true,
      };

    case settingActions.UPDATE_APP_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // price charges

    case settingActions.GET_PRICE_CHARGES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case settingActions.GET_PRICE_CHARGES_SUCCESS:
      return {
        ...state,
        loading: false,
        priceCharges: payload.data,
      };

    case settingActions.GET_PRICE_CHARGES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        priceCharges: {},
      };

    case settingActions.UPDATE_PRICE_CHARGES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case settingActions.UPDATE_PRICE_CHARGES_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusChange: true,
      };

    case settingActions.UPDATE_PRICE_CHARGES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case "IS_UPDATE_STATUS":
      return {
        ...state,
        isStatusChange: false,
      };

    default:
      return state;
  }
};
