import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_APP_VERSION_REQUEST = "GET_APP_VERSION_REQUEST";
export const GET_APP_VERSION_SUCCESS = "GET_APP_VERSION_SUCCESS";
export const GET_APP_VERSION_FAILURE = "GET_APP_VERSION_FAILURE";

export const UPDATE_APP_VERSION_REQUEST = "UPDATE_APP_VERSION_REQUEST";
export const UPDATE_APP_VERSION_SUCCESS = "UPDATE_APP_VERSION_SUCCESS";
export const UPDATE_APP_VERSION_FAILURE = "UPDATE_APP_VERSION_FAILURE";

export const GET_PRICE_CHARGES_REQUEST = "GET_PRICE_CHARGES_REQUEST";
export const GET_PRICE_CHARGES_SUCCESS = "GET_PRICE_CHARGES_SUCCESS";
export const GET_PRICE_CHARGES_FAILURE = "GET_PRICE_CHARGES_FAILURE";

export const UPDATE_PRICE_CHARGES_REQUEST = "UPDATE_PRICE_CHARGES_REQUEST";
export const UPDATE_PRICE_CHARGES_SUCCESS = "UPDATE_PRICE_CHARGES_SUCCESS";
export const UPDATE_PRICE_CHARGES_FAILURE = "UPDATE_PRICE_CHARGES_FAILURE";

export const getAppVersion = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_APP_VERSION_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/setting/admin/get/app/version/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_APP_VERSION_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_APP_VERSION_FAILURE, error);
    }
  };
};

export const updateAppVersion = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_UPDATE_STATUS" });
      dispatch({ type: UPDATE_APP_VERSION_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/setting/update/app/version/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_APP_VERSION_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, UPDATE_APP_VERSION_FAILURE, error);
    }
  };
};

export const getPriceCharges = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRICE_CHARGES_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/charges/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_PRICE_CHARGES_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_PRICE_CHARGES_FAILURE, error);
    }
  };
};

export const updatePriceCharges = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_UPDATE_STATUS" });
      dispatch({ type: UPDATE_PRICE_CHARGES_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/charges/update/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_PRICE_CHARGES_SUCCESS, payload: response.data });
      toast.success("Updated Successfully", {
        position: "top-center",
        autoClose: 3000,
      });
    } catch (error) {
      errorHandling(dispatch, UPDATE_PRICE_CHARGES_FAILURE, error);
    }
  };
};
