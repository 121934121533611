import React, { useEffect, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { LuFilter } from "react-icons/lu";
import { Col, FormGroup, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { reportsList } from "../../../../redux/report/report.actions";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const [data, setData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, list } = useSelector((state) => state.reportInfo);

  useEffect(() => {
    dispatch(reportsList());
  }, []);

  useEffect(() => {
    if (list && list.length > 0) {
      setData(list);
    } else {
      setData([]);
    }
  }, [list]);

  const columns = [
    {
      name: "Created At",
      selector: (row) =>
        moment(new Date(row.createdAt)).format("DD-MM-YYYY h:mm a"),
      sortable: true,
    },
    {
      name: "Reported For",
      selector: (row) => {
        if (row.productDetails.length > 0) {
          return (
            <div
              style={{
                color: "#6950ba",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/product/${row.productDetails[0]?._id}`)}
            >
              {row.productDetails[0]?.name}
            </div>
          );
        } else if (row.reportVetOrVendorDetails.length > 0) {
          return (
            <div
              style={{
                color: "#6950ba",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  `/${
                    row.reportVetOrVendorDetails[0]?.role === "vet"
                      ? "vet"
                      : "vendor"
                  }/${row.reportVetOrVendorDetails[0]?._id}`
                )
              }
            >
              {row.reportVetOrVendorDetails[0]?.additionalDetails?.name}
            </div>
          );
        } else if (row.reportUserDetails.length > 0) {
          return (
            <div
              style={{
                color: "#6950ba",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/user/${row.reportUserDetails[0]?._id}`)}
            >
              {row.reportUserDetails[0]?.name}
            </div>
          );
        }
        return "N/A";
      },
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => {
        if (row.productDetails.length > 0) {
          return "Product";
        } else if (row.reportVetOrVendorDetails.length > 0) {
          return row.reportVetOrVendorDetails[0]?.role === "vet"
            ? "Vet"
            : "Vendor";
        } else if (row.reportUserDetails.length > 0) {
          return "User";
        }
        return "N/A";
      },
      sortable: true,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
    },
    {
      name: "Reported By",
      selector: (row) => {
        if (row.reportByVetDetails.length > 0) {
          return (
            <div
              style={{
                color: "#6950ba",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  `/${
                    row.reportByVetDetails[0]?.role === "vet" ? "vet" : "vendor"
                  }/${row.reportByVetDetails[0]?._id}`
                )
              }
            >
              {`${row.reportByVetDetails[0]?.additionalDetails?.name} (${
                row.reportByVetDetails[0]?.role === "vet" ? "Vet" : "Vendor"
              })`}
            </div>
          );
        } else if (row.reportByDetails.length > 0) {
          return (
            <div
              style={{
                color: "#6950ba",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/user/${row.reportByDetails[0]?._id}`)}
            >
              {`${row.reportByDetails[0]?.name} (User)`}
            </div>
          );
        }
        return "N/A";
      },
      sortable: true,
    },
    {
      name: "More Info",
      selector: (row) => (
        <div className="d-flex ">
          <ViewMoreBtn redirectUrl={`/report/${row?._id}`} />
        </div>
      ),
    },
  ];

  const filteredData =
    data &&
    data.length > 0 &&
    data.filter((item) => {
      const reportedFor =
        item.productDetails.length > 0
          ? item.productDetails[0]?.name
          : item.reportVetOrVendorDetails.length > 0
          ? item.reportVetOrVendorDetails[0]?.additionalDetails?.name
          : item.reportUserDetails.length > 0
          ? item.reportUserDetails[0]?.name
          : "N/A";

      return reportedFor.toLowerCase().includes(searchText.toLowerCase());
    });

  const handleSearch = (value) => {
    setCurrentPage(1);
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData =
    filteredData &&
    filteredData.length > 0 &&
    filteredData.slice(
      (currentPage - 1) * paginationPerPage,
      currentPage * paginationPerPage
    );

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="page-header">
              <h3 className="page-title">Reports</h3>
            </div>
            <div className="row w-100">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                      <DataTable
                        // title={<h6 className="card-title">Pets Category List</h6>}
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100">
                              <Col md={12} className="d-flex gap-2">
                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                  <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                    <LuFilter />
                                    Filter By :
                                  </span>
                                  <div className="table_filter">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search by Reported for"
                                      value={searchText}
                                      onChange={(e) => {
                                        setCurrentPage(1);
                                        handleSearch(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
