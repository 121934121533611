import React, { useEffect, useState } from "react";
import * as paymentActions from "../../../../redux/payments/payment.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormGroup } from "react-bootstrap";
import { reportsDetails } from "../../../../redux/report/report.actions";
import moment from "moment";

const ReportsDetails = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { details } = useSelector((state) => state.reportInfo);

  useEffect(() => {
    if (id) {
      dispatch(reportsDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && Object.keys(details).length > 0) {
      setData(details);
    } else {
      setData({});
    }
  }, [details]);

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {data && Object.keys(data).length > 0 ? (
            <>
              <div>
                <div className="page-header">
                  <h3 className="page-title">Report Details</h3>
                </div>
                <div className="container-fluid">
                  {
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <FormGroup className="mb-3">
                              <label>Reported For</label>
                              <input
                                type="text"
                                style={{ cursor: "pointer" }}
                                placeholder="Did Not Provide"
                                value={
                                  data?.productDetails?.length > 0
                                    ? data?.productDetails[0]?.name
                                    : data?.reportUserDetails?.length > 0
                                    ? data?.reportUserDetails?.[0]?.name
                                    : data?.reportVetOrVendorDetails?.[0]
                                        ?.additionalDetails?.name
                                }
                                onClick={() => {
                                  if (data?.productDetails?.length > 0) {
                                    navigate(`/product/${data._id}`);
                                  } else if (
                                    data?.reportUserDetails?.length > 0
                                  ) {
                                    navigate(
                                      `/user/${data?.reportUserDetails[0]?._id}`
                                    );
                                  } else if (
                                    data?.reportVetOrVendorDetails?.length > 0
                                  ) {
                                    const role =
                                      data?.reportVetOrVendorDetails[0]?.role;
                                    if (role === "vet") {
                                      navigate(
                                        `/vet/${data?.reportVetOrVendorDetails[0]?._id}`
                                      );
                                    } else if (role === "vendor") {
                                      navigate(
                                        `/vendor/${data?.reportVetOrVendorDetails[0]?._id}`
                                      );
                                    }
                                  }
                                }}
                                className="form-control"
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className="col-6">
                            <FormGroup className="mb-3">
                              <label>Type</label>
                              <input
                                type="text"
                                placeholder="Did Not Provide"
                                value={
                                  data?.productDetails?.length > 0
                                    ? "Product"
                                    : data?.reportUserDetails?.length > 0
                                    ? "User"
                                    : data?.reportVetOrVendorDetails?.[0]
                                        ?.role == "vet"
                                    ? "Vet"
                                    : "Vendor"
                                }
                                className="form-control"
                                readOnly
                              />
                            </FormGroup>
                          </div>
                          <div className="col-6">
                            <FormGroup className="mb-3">
                              <label>Reported Date</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Did Not Provide"
                                value={moment(new Date(data.createdAt)).format(
                                  "DD-MM-YYYY h:mm a"
                                )}
                                rows="4"
                                readOnly
                              ></input>
                            </FormGroup>
                          </div>
                          <div className="col-6">
                            <FormGroup className="mb-3">
                              <label>Reported By</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Did Not Provide"
                                style={{ cursor: "pointer" }}
                                value={
                                  data?.reportByVetDetails?.length > 0
                                    ? data?.reportByVetDetails[0]
                                        ?.additionalDetails?.name
                                    : data?.reportByDetails?.[0]?.name
                                }
                                rows="4"
                                onClick={() => {
                                  if (data?.reportByDetails?.length > 0) {
                                    navigate(
                                      `/user/${data?.reportByDetails[0]?._id}`
                                    );
                                  } else if (
                                    data?.reportByVetDetails?.length > 0
                                  ) {
                                    const role =
                                      data?.reportByVetDetails[0]?.role;
                                    if (role === "vet") {
                                      navigate(
                                        `/vet/${data?.reportByVetDetails[0]?._id}`
                                      );
                                    } else if (role === "vendor") {
                                      navigate(
                                        `/vendor/${data?.reportByVetDetails[0]?._id}`
                                      );
                                    }
                                  }
                                }}
                                readOnly
                              ></input>
                            </FormGroup>
                          </div>
                          <div className="col-12">
                            <FormGroup className="mb-3">
                              <label>Reason</label>
                              <textarea
                                placeholder="Did Not Provide"
                                value={data.reason || "Did Not Provide"}
                                className="form-control"
                                readOnly
                                rows="4"
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>
          ) : (
            <h2 className="text-center mt-3 text-muted">No Data Found</h2>
          )}
        </>
      )}
    </>
  );
};

export default ReportsDetails;
