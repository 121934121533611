import * as reportActions from "./report.actions";

export const reportFeatureKey = "reportInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case reportActions.REPORT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case reportActions.REPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case reportActions.REPORT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };
    case reportActions.REPORT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case reportActions.REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data,
      };

    case reportActions.REPORT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    default:
      return state;
  }
};
