import React, { useEffect, useState } from "react";
import * as authActions from "../../../../redux/auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import ActivePriceChart from "./ActivePriceChart";
import { getAdminHistoryCounts } from "../../../../redux/adminHistory/adminHistory.actions";

const PriceModule = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const { loading, counts } = useSelector((state) => state.adminHistoryInfo);
  useEffect(() => {
    dispatch(getAdminHistoryCounts());
  }, []);

  useEffect(() => {
    if (counts && Object.keys(counts).length > 0) {
      setData(counts);
    } else {
      setData({});
    }
  }, [counts]);

  console.log(data, "counts");
  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {data && Object.keys(data).length > 0 && (
            <>
              <div>
                <div className="page-header">
                  <h3 className="page-title"> Price Module </h3>
                </div>
                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body px-3 py-1">
                        <div className="row px-3">
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card policies">
                              <div className="dashboard-card-name">
                                <div className="dash-name">Today's Orders</div>
                                <div className="dash-number">
                                  {data.today?.totalOrders}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-policies.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card total-renewal">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Today's Homecares
                                </div>
                                <div className="dash-number">
                                  {data.today?.totalHomecare}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-renewal.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card premium">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Today's Instant Consultations
                                </div>
                                <div className="dash-number">
                                  {data.today?.totalConsultationInstant}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-premium.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card payout">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Today's Schedule Consultations
                                </div>
                                <div className="dash-number">
                                  {data.today?.totalConsultationSchedule}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card policies">
                              <div className="dashboard-card-name">
                                <div className="dash-name">Weekly Orders</div>
                                <div className="dash-number">
                                  {data.weekly?.totalOrders}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card total-renewal">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Weekly Homecares
                                </div>
                                <div className="dash-number">
                                  {data.weekly?.totalHomecare}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card premium">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Weekly Instant Consultations
                                </div>
                                <div className="dash-number">
                                  {data.weekly?.totalConsultationInstant}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card payout">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Weekly Schedule Consultations
                                </div>
                                <div className="dash-number">
                                  {data.weekly?.totalConsultationSchedule}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card policies">
                              <div className="dashboard-card-name">
                                <div className="dash-name">Monthly Orders</div>
                                <div className="dash-number">
                                  {data.monthly?.totalOrders}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card total-renewal">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Monthly Homecares
                                </div>
                                <div className="dash-number">
                                  {data.monthly?.totalHomecare}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card premium">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Monthly Instant Consultations
                                </div>
                                <div className="dash-number">
                                  {data.monthly?.totalConsultationInstant}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6 col-md-3 col-sm-6 px-2 py-2">
                            <div className="dashboard-card payout">
                              <div className="dashboard-card-name">
                                <div className="dash-name">
                                  Monthly Schedule Consultations
                                </div>
                                <div className="dash-number">
                                  {data.monthly?.totalConsultationSchedule}
                                </div>
                              </div>
                              <div className="dash-icon">
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ActivePriceChart />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PriceModule;
