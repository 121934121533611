import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const REPORT_LIST_REQUEST = "REPORT_LIST_REQUEST";
export const REPORT_LIST_SUCCESS = "REPORT_LIST_SUCCESS";
export const REPORT_LIST_FAILURE = "REPORT_LIST_FAILURE";

export const REPORT_DETAILS_REQUEST = "REPORT_DETAILS_REQUEST";
export const REPORT_DETAILS_SUCCESS = "REPORT_DETAILS_SUCCESS";
export const REPORT_DETAILS_FAILURE = "REPORT_DETAILS_FAILURE";

export const reportsList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: REPORT_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/report/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: REPORT_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, REPORT_LIST_FAILURE, error);
    }
  };
};

export const reportsDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REPORT_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/report/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: REPORT_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, REPORT_DETAILS_FAILURE, error);
    }
  };
};
